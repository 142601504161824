html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  background-color: #334a66;
  overflow: hidden;
}

.stf__parent {
  left: 1% !important;
}

/* GALLERY STYLE */
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
}

.image-gallery-icon {
  filter: none !important;
}

.image-gallery-icon:hover {
  color: #c7bc96 !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  display: none !important;
}

.image-gallery-thumbnail:hover {
  border: 4px solid #cfc49d !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 4px solid #cfc49d !important;
}

.image-gallery-swipe {
  border: 4px solid #cbc6a0 !important;
}
